import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Loading from 'components/loading/Loading';
import './History.scss';
import historyTypes from 'constants/history-types';
import PaperUploaded from "./PaperUploaded";
import PaperApproved from "./PaperApproved";
import PaperDeclined from "./PaperDeclined";
import FileUploaded from "./FileUploaded";
import OrderCompleted from "./OrderCompleted";
import OrderUpdated from "./OrderUpdated";
import OrderFinalizing from "./OrderFinalizing";
import FinalizationDeclined from "./FinalizationDeclined";
import RevisionRequested from "./RevisionRequested";
import RevisionInitiated from "./RevisionInitiated";
import WriterApplied from "./WriterApplied";
import WriterApproved from "./WriterApproved";
import TopicChanged from "./TopicChanged";
import DisputeStarted from "./DisputeStarted";
import OrderRefunded from "./OrderRefunded";
import Message from './Message'

const OrderHistory = ({ history }) => {
	const loading = useSelector((state) => state.order.loading);

	const getHistoryComponent = (item, type) => {
		switch (type) {
			case historyTypes.PAPER_UPLOADED:
				return <PaperUploaded
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.PAPER_APPROVED:
				return <PaperApproved
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
				  	}
				/>
				break;
			case historyTypes.PAPER_DECLINED:
				return <PaperDeclined
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.FILE_UPLOADED:
				return <FileUploaded
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.ORDER_COMPLETED:
				return <OrderCompleted
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.ORDER_UPDATED:
				return <OrderUpdated
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
				  	}
				/>
				break;
			case historyTypes.ORDER_FINALIZING:
				return <OrderFinalizing
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.FINALIZATION_DECLINED:
				return <FinalizationDeclined
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.REVISION_REQUESTED:
				return <RevisionRequested
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.REVISION_INITIATED:
				return <RevisionInitiated
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.WRITER_APPLIED:
				return <WriterApplied
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.WRITER_APPROVED:
				return <WriterApproved
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.WRITER_ASSIGNED:
				return <OrderCompleted
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.TOPIC_CHANGED:
				return <TopicChanged
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
				  	}
				/>
				break;
			case historyTypes.DISPUTE_STARTED:
				return <DisputeStarted
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.ORDER_REFUNDED:
				return <OrderRefunded
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			case historyTypes.MESSAGE:
				return <Message
					attaches={
						item.attaches
					}
					className='py-2'
					type={
						item.type.name
					}
				/>
				break;
			default:
				return '';
		}
	}

	const renderHistory = (history) => {
		return getHistoryComponent(history, history.type.name);

	}

	return (
		<>
			{history.data?.length > 0 ? (
				<div className='order-history border'>
					<h3 className='order-history__ttl box-border box-padding fs-6 bg-white mb-0'>
						History
					</h3>
					{
						<ul className='order-history__list list-unstyled'>
							{history.data?.map((item, idx) => (
								<li key={idx}>
									{loading ? (
										<Loading />
									) : (
										renderHistory(item, item.type.name)
									)}
								</li>
							))}
						</ul>
					}
				</div>
			) : ''}
		</>
	);
};

OrderHistory.propTypes = {
	history: PropTypes.any
};

OrderHistory.defaultProps = {
	history: {} || []
};

export default OrderHistory;
