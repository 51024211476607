import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatMessage from './chat-message';
import ChatForm from './chat-form';
import ListIcon from './header-icons/list-icon';
import MessageHeaderIcon from './header-icons/message-icon';
import './Message.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { changeTypeSegment, setIdMessageSelected, updateMessageInfo } from 'store/slices/message';
import MessageList from './message-list';
import { TYPE_ACTIVE } from 'constants/message';
import { fetchOrderById, getTotalUnreadMessage } from 'store/thunks';

function Message() {
    const { isShowMessage, idMessageSelected, typeSegment, messageInfo } = useSelector((state) => state.message);
    const { order } = useSelector((state) => state.order);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleIconListClick = () => {
        dispatch(setIdMessageSelected(null));
        dispatch(changeTypeSegment(TYPE_ACTIVE.DASHBOARD));
        dispatch(updateMessageInfo({
            client: order?.data?.client,
            order: {
                status: order?.data?.status,
                id: order?.data?.id,
                signature: order?.data?.signature,
                messagable: order?.data?.messagable,
                uid: order?.data?.uid
            },
            loading: false
        }));
        dispatch(getTotalUnreadMessage());
    };

    const handleIconMessageClick = () => {
        dispatch(setIdMessageSelected(id));
        dispatch(changeTypeSegment(TYPE_ACTIVE.MESSAGE));

        dispatch(updateMessageInfo({
            client: order?.data?.client,
            order: {
                status: order?.data?.status,
                id: order?.data?.id,
                signature: order?.data?.signature,
                messagable: order?.data?.messagable,
                uid: order?.data?.uid
            },
            loading: false
        }));
    };

    const handleRedirect = () => {
        const orderId = `${messageInfo?.order?.id}`;

        if (!orderId) return;

        if (orderId !== id) {
            navigate(`/details/${orderId}`);
            dispatch(fetchOrderById(orderId));
        }
    };

    const renderHeaderTitle = () => {
        if (messageInfo?.order?.id && typeSegment === TYPE_ACTIVE.MESSAGE) {
            return <p>Message / <span onClick={handleRedirect} className={`message-order uid--${messageInfo?.order?.status}`}>{messageInfo?.order?.uid}</span></p>;
        }

        return <p>Message</p>;
    };

    return <div className={`message__box ${isShowMessage ? 'show' : ''}`}>
        {
            isShowMessage &&
                <>
                    <div className='header'>
                        {renderHeaderTitle()}
                        <div className='list-icon'>
                            <ListIcon onClick={idMessageSelected && handleIconListClick} isActive ={Boolean(id && typeSegment === TYPE_ACTIVE.DASHBOARD)}/>
                            {(id || idMessageSelected) && <MessageHeaderIcon onClick={handleIconMessageClick} isActive={typeSegment === TYPE_ACTIVE.MESSAGE}/>}
                        </div>
                    </div>
                    <div className='messages'>
                        {
                          idMessageSelected && typeSegment === TYPE_ACTIVE.MESSAGE ? <ChatMessage/> : <MessageList/>
                        }
                    </div>
                    {(idMessageSelected || typeSegment === TYPE_ACTIVE.MESSAGE) && <div className='form-input'><ChatForm/></div>}
                </>

        }
    </div>;
}

export default Message;
