import React, { useEffect, useRef, useState } from 'react';
import MessageItem from './message-item';
import EmptyMessage from '../empty-message';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderMessage, getPrevMessageDetail } from 'store/thunks';
import {
    changeTypeSegment,
    concatMessageNotification, updateStatusGettedMessageData
} from 'store/slices/message';
import { FROM, TYPE_ACTIVE } from 'constants/message';
import LoadingMessage from '../loading-message';

function MessageList() {
    const dispatch = useDispatch();
    const { messageNotificationData, hasGettedData } = useSelector((state) => state.message);
    const ref = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingLoadMore, setIsLoadingLoadMore] = useState(false);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [isLoadMore, setIsLoadMore] = useState(true);
    const [number, setNumber] = useState(0);
    let timer = null;
    const abortController = new AbortController();

    useEffect(() => {
       if (!hasGettedData) {
           const signal = abortController.signal;
           getInitData(signal);
       } else {
           setIsLoading(false);
       }
    }, [number]);

    useEffect(() => {
        dispatch(changeTypeSegment(TYPE_ACTIVE.DASHBOARD));
        return () => {
            clearTimeout(timer);
                abortController.abort();
        };
    }, []);

    const checkScrollbarVisibility = () => {
        if (!ref.current) {
            return false;
        }

        const { scrollHeight, clientHeight } = ref.current;

        return scrollHeight > clientHeight + 150;
    };

    const getInitData = (signal) => {
        const hasVerticalScrollbar = checkScrollbarVisibility();
        if (!hasVerticalScrollbar) {
            setIsLoadingLoadMore(true);
            const newPage = pageCurrent + 1;
            dispatch(getOrderMessage({ page: newPage, signal })).unwrap()
                .then(async ({ data: dataRes, last_page, per_page }) => {
                    if (dataRes.length) {
                        const data = await Promise.all(dataRes.map(async (order) => {
                            const mes = await getOrderMessageItem(order.signature);
                            return { ...order, message: mes?.[0] || null };
                        }));
                        dispatch(updateStatusGettedMessageData(true));
                        dispatch(concatMessageNotification(data));
                    }

                    if (dataRes.length < per_page || newPage === last_page) {
                        setIsLoadingLoadMore(false);
                        setIsLoadMore(false);
                    } else {
                        timer = setTimeout(() => {
                            setPageCurrent(newPage);
                            setNumber((prev) => ++prev);
                        }, 0);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                }).finally(() => {
                timer = setTimeout(() => {
                    const hasVerticalScrollbar = checkScrollbarVisibility();
                    if (hasVerticalScrollbar) {
                        setIsLoadingLoadMore(false);
                    }
                }, 0);
            });
        }
    };
    const getData = (page, changeLoading) => {
        changeLoading(true);
        dispatch(getOrderMessage({ page })).unwrap()
            .then(async ({ data: dataRes, last_page }) => {
                if (dataRes.length) {
                    const data = await Promise.all(dataRes.map(async (order) => {
                        const mes = await getOrderMessageItem(order.signature);
                        return { ...order, message: mes?.[0] };
                    }));
                    dispatch(concatMessageNotification(data));
                    if (last_page === page) {
                        setIsLoadMore(false);
                    }
                }

                changeLoading(false);
            });
    };

    const getOrderMessageItem = async (orderSignature) => {
        let data = [];
        await dispatch(getPrevMessageDetail({ orderSignature, limit: 1 })).unwrap().then((mes) => {
            data = mes;
        });
        return data;
    };

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = ref.current;

        if (scrollHeight === (scrollTop + clientHeight) && !isLoadingLoadMore && isLoadMore) {
            const newPage = pageCurrent + 1;
            getData(newPage, setIsLoadingLoadMore);
            setPageCurrent(newPage);
        }
    };

    const checkHasRead = (orderMessage) => {
        const { message, unread_messages } = orderMessage;
        if (!message) {
            return true;
        }
        if (message.from === FROM) {
            return true;
        }

        return unread_messages === 0;
    };

    const sortCondition = (a, b) => {
        if (a.created_at > b.created_at) {
            return -1;
        }
        if (a.created_at < b.created_at) {
            return 1;
        }
        return 0;
    };

    const renderContent = () => {
        if (isLoading) {
            return <LoadingMessage className='px-3 py-3'/>;
        }

        if (!messageNotificationData.length) {
            return <EmptyMessage/>;
        }

        return <div className='message-notification' ref={ref} onScroll={handleScroll}>
            {
                [...messageNotificationData].sort((a, b) => sortCondition(a.message, b.message)).map((item) => {
                    return <MessageItem totalUnread={item.unread_messages}
                                        orderStatus={item.status} key={item.uid}
                                        id={item.id}
                                        unread={!checkHasRead(item)} message={item.message}
                                        uid={item.uid}
                                        signature={item.signature}
                                        messagable = {item.messagable}
                                        client = {item.client}
                            />;
                })
            }

            {isLoadingLoadMore && <LoadingMessage className='px-3 py-2'/>}
        </div>;
    };

    return <>
        {renderContent()}
    </>;
}
export default MessageList;
