import { createSlice } from '@reduxjs/toolkit';
import { FROM, TYPE_ACTIVE } from 'constants/message';
import { fetchOrderById, getCursorMessage, getOrderDetail, getTotalUnreadMessage, sendMessage } from '../thunks';

const initialState = {
    messageDetailData: [],

    groupSignatureSelected: '',
    isShowMessage: false,
    idMessageSelected: '',
    messageHighlight: {
        isMessageOrderActivityClicked: false,
        count: 0,
        activityTime: null
    },
    typeSegment: TYPE_ACTIVE.DASHBOARD,
    showTitleNewMessage: false,

    autoScroll: 0,

    hasLoadMore: {
        prev: true,
        next: false
    },

    timeMark: new Date().getTime(),

    messageInfo: {
        loading: false,
        client: {},
        order: {
            id: '',
            signature: '',
            status: '',
            messagable: false,
            uid: ''
        }
    },
    //Message Notification State
    messageNotificationData: [],
    hasGettedData: false,

    totalUnread: 0

};

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        toggleMessage: (state, action) => {
            if (action.payload === undefined) {
                state.isShowMessage = !state.isShowMessage;
                return;
            }

            state.isShowMessage = action.payload;
        },

        updateMessageInfo: (state, action) => {
            const currentInfo = { ...state.messageInfo };
            state.messageInfo = Object.assign(currentInfo, action.payload);
        },

        setIdMessageSelected: (state, action) => {
            state.idMessageSelected = action.payload;
        },

        updateShowTitleNewMessage: (state, action) => {
            state.showTitleNewMessage = action.payload;
        },

        addMessageLocal: (state, action) => {
            const message = action.payload;
            const currentMessageDetailData = [...state.messageDetailData];
            currentMessageDetailData.push(message);
            state.timeMark = new Date().getTime();
            state.messageDetailData = currentMessageDetailData;
        },

        updateMessageLocal: (state, action) => {
            const message = action.payload;
            const currentData = [...state.messageDetailData];

            const index = currentData.findIndex((i) => i.messageId === message.messageId);
            if (index !== -1) {
                currentData.splice(index, 1, message);
                state.messageDetailData = currentData;
            }
        },

        setMessageDetailData: (state, action) => {
            state.messageDetailData = action.payload;
        },

        changeTypeSegment: (state, action) => {
            state.typeSegment = action.payload || TYPE_ACTIVE.DASHBOARD;
        },

        listenEventSendMessage: (state, action) => {
            const message = action.payload;
            const currentData = [...state.messageDetailData];

            state.timeMark = new Date().getTime();
            if (message.from !== FROM) {
                state.showTitleNewMessage = true;
            }

            const indexMessage = currentData.findIndex((i) => i.messageId === `${message.created_at}-${FROM}` || i.messageId === message.messageId);
            if (indexMessage !== -1) {
                currentData.splice(indexMessage, 1, message);
            } else {
                currentData.push(message);
            }

            state.messageDetailData = currentData;
        },

        concatMessage: (state, action) => {
            const { data, position } = action.payload;
            let currentData = [...state.messageDetailData];

            if (position === 'before') {
                currentData = data.concat(currentData);
            } else {
                currentData = currentData.concat(data);
            }

            state.messageDetailData = currentData;
        },

        updateStatusHighlight: (state, action) => {
            state.messageHighlight = action.payload ? Object.assign(state.messageHighlight, action.payload) : {
                isMessageOrderActivityClicked: false,
                count: 0,
                activityTime: null
            };
        },

        autoScrollWhenSendMessage: (state, action) => {
            state.autoScroll = action.payload !== 0 ? state.autoScroll + 1 : 0;
        },

        changeLoadMore: (state, action) => {
            state.hasLoadMore = Object.assign(state.hasLoadMore, action.payload);
        },

        updateTimeMark: (state, action) => {
            state.timeMark = new Date(action.payload).getTime() || new Date().getTime();
        },

        resetState: (state) => {
            state.messageDetailData = [];

            state.groupSignatureSelected = '';

            state.messageHighlight = {
                isMessageOrderActivityClicked: false,
                count: 0,
                activityTime: new Date()
            };

            state.typeSegment = TYPE_ACTIVE.DASHBOARD;
            state.showTitleNewMessage = false;

            state.autoScroll = 0;

            state.hasLoadMore = {
                prev: true,
                next: false
            };
            state.timeMark = new Date().getTime();
        },

        //Message Notification State

        concatMessageNotification: (state, action) => {
            const currentData = [...state.messageNotificationData];
            const newData = action.payload;
            state.messageNotificationData = currentData.concat(newData);
        },

        updateStatusGettedMessageData: (state, action) => {
            state.hasGettedData = Boolean(action.payload);
        },

        resetStateMessageNotification: (state, action) => {
            state.messageNotificationData = [];
            state.hasGettedData = false;
        },

        decrementTotalUnread: (state, action) => {
            const orderId = action.payload;
            const currentData = [...state.messageNotificationData];

            const index = currentData.findIndex((i) => i.id === orderId);

            if (index !== -1) {
                state.totalUnread -= currentData[index].unread_messages;
                currentData.splice(index, 1, { ...currentData[index], unread_messages: 0 });
                state.messageNotificationData = currentData;
            }
        },

        listenEventOfMessageNotification: (state, action) => {
            const orderNotification = action.payload;
            const currentData = [...state.messageNotificationData];
            const isFromWriter = orderNotification.message.from === FROM;
            const isShowMessage = state.isShowMessage;
            const index = currentData.findIndex((i) => i.signature === orderNotification.signature);
            const currentStateGetted = state.hasGettedData;
            const { order } = state.messageInfo;
            if ((isShowMessage && state.typeSegment === TYPE_ACTIVE.MESSAGE && order.id !== orderNotification.id) || (isShowMessage && state.typeSegment === TYPE_ACTIVE.DASHBOARD) || !isShowMessage) {
                if (!isFromWriter) {
                    state.totalUnread += 1;
                } else {
                    if (index !== -1) {
                        state.totalUnread -= currentData[index].unread_messages;
                        currentData[index]['unread_messages'] = 0;
                    }
                }
            }
            if (currentStateGetted) {
                if (index !== -1) {
                    const currentUnread = currentData[index].unread_messages;
                    const messageNotificationFinal = { ...orderNotification };

                    messageNotificationFinal['unread_messages'] = getNewTotalUnreadItem({ currentUnread, isFromWriter, isShowMessage, orderNotification, typeSegment: state.typeSegment, order });

                    if (index === 0) {
                        currentData.splice(index, 1, messageNotificationFinal);
                    } else {
                        currentData.splice(index, 1);
                        currentData.unshift(messageNotificationFinal);
                    }
                } else {
                    currentData.unshift({ ...orderNotification, unread_messages: getNewTotalUnreadItem({ currentUnread: 0, isFromWriter, isShowMessage, orderNotification, typeSegment: state.typeSegment, order }) });
                }
            }
            state.messageNotificationData = currentData;
        },
        resetAllState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(sendMessage.fulfilled, (state, action) => {
            const { message } = action.payload;
            const currentData = [...state.messageDetailData];
            const index = currentData.findIndex((i) => i.messageId === message.messageId || i.messageId === `${message.created_at}-${FROM}`);
            if (index !== -1) {
                currentData.splice(index, 1, message);
                state.messageDetailData = currentData;
            }
        });

        builder.addCase(getTotalUnreadMessage.fulfilled, (state, action) => {
            state.totalUnread = action.payload;
        });

        builder.addCase(getCursorMessage.fulfilled, (state, action) => {
            const { data } = action.payload;
            if (data) {
                const orderId = action.meta.arg;
                const currentData = [...state.messageNotificationData];
                const index = currentData.findIndex((item) => item.id === orderId);

                if (index !== -1) {
                    const item = currentData[index];
                    const currentUnread = item.unread_messages;

                    const { unread_messages, total } = data;
                    state.totalUnread = state.totalUnread - currentUnread + unread_messages;
                    currentData.splice(index, 1, { ...item, total, unread_messages });
                    state.messageNotificationData = currentData;
                }
            }
        });

        builder.addCase(getOrderDetail.pending, (state) => {
            state.messageInfo = {
                loading: true,
                client: {},
                order: {
                    id: '',
                    signature: '',
                    status: '',
                    messagable: false,
                    uid: ''
                }
            };
        }).addCase(getOrderDetail.fulfilled, (state, action) => {
            const { data: order } = action.payload;

            state.messageInfo = {
                loading: false,
                client: order.client,
                order: {
                    id: order.id,
                    signature: order.signature,
                    status: order.status,
                    messagable: order.messagable,
                    uid: order.uid
                }
            };
        });

        builder.addCase(fetchOrderById.fulfilled, (state, action) => {
            const order = action.payload[0]?.data?.data;

            if (!order) {
                return;
            }
            if (state.typeSegment === TYPE_ACTIVE.DASHBOARD || !state.messageInfo?.order?.id) {
                state.messageInfo = {
                    client: order.client,
                    order: {
                        id: order.id,
                        signature: order.signature,
                        status: order.status,
                        messagable: order.messagable,
                        uid: order.uid
                    }
                };
            }
        });
    }
});

const getNewTotalUnreadItem = ({ isFromWriter, isShowMessage, order, orderNotification, currentUnread, typeSegment }) => {
    if (isFromWriter) {
        return 0;
    }

    if (!isShowMessage && !isFromWriter) {
        return currentUnread + 1;
    }

    if (typeSegment === TYPE_ACTIVE.DASHBOARD || typeSegment === TYPE_ACTIVE.MESSAGE && order.id !== orderNotification.id) {
        return currentUnread + 1;
    }

    return 0;
};

export const {
    toggleMessage,
    setIdMessageSelected,
    addMessageLocal,
    setMessageDetailData,
    changeTypeSegment,
    listenEventSendMessage,
    concatMessage,
    updateMessageLocal,
    updateStatusHighlight,
    autoScrollWhenSendMessage,
    changeLoadMore,
    resetState,
    updateTimeMark,
    updateShowTitleNewMessage,
    updateMessageInfo,

    concatMessageNotification,
    resetStateMessageNotification,
    listenEventOfMessageNotification,
    decrementTotalUnread,
    updateStatusGettedMessageData,
    resetAllState
} = messageSlice.actions;

export default messageSlice.reducer;
